<template>
  <div>
    <!-- 中文 -->
    <div v-if="$store.state.show">
      <h2>iOS平台</h2>
      <h3>1.申请测试Key</h3>
      <p>
        TiSDK 的初始化接口，需要使用 Key 进行鉴权，需要向商务申请，申请方法：
      </p>
      <ul>
        <li>（1）拨打17136231898进行咨询。</li>
        <li>（2）加QQ3599350770 / 微信 17136231898进行咨询</li>
      </ul>
      <h3>2. 下载配置SDK</h3>
      <ul>
        <li>（1）软件需求：iOS 8.0及以上、Xcode 8及以上</li>
        <li>
          （2）最新版本下载集成：
          <ul class="ul_style">
            <li>
              SDK下载地址：<a href="https://gitee.com/tillusory/TiSDK_iOS"
                >TiSDK</a
              >
            </li>
            <li>
              演示Dome下载地址：<a
                href="https://gitee.com/tillusory/Ti_Demo_iOS"
                >Ti_Demo_iOS</a
              >
            </li>
          </ul>
        </li>
      </ul>
      <h3>3. 检查资源</h3>
      <p>
        使用者确认IOS 版本的 TiSDK 包<span>TiSDK_iOS</span>
        包含:<span>TiSDK.framework</span>、<span>TiUI 文件夹</span>(可选)、
        <span>TiSDKResource.bundle</span>。 SDK资源示意图：
      </p>
      <img src="@/assets/developer/ios1.png" alt="SDK资源示意图" />
      <h3>4. 导入工程</h3>
      <ul>
        <li>
          (1) <span>TiSDK_iOS</span>文件夹拷贝到
          <span>IOS</span> 工程目录，然后引入到工程中,其中
          <span>TiSDK.framework</span> 与UI文件中的 <span>Masonry</span>,
          <span>SSZipArchive</span>均为动态库需要做嵌入操作;
        </li>
        <li>
          (2) 如果您原工程已添加 <span>Masonry</span> ,
          <span>SSZipArchive</span>, 可将 <span>TiUITool</span> 目录下的
          <span>Masonry.framework</span>、 <span>ZipArchive. framework</span>
          删除，避免冲突;
        </li>
        <li>(3) 添加SDK所使用的依赖库 <span>libc++.tbd</span>;</li>
        <li>
          (4) 依赖库及动态库嵌入操作示意图：
          <img src="@/assets/developer/ios2.png" alt="嵌入操作示意图" />
        </li>
      </ul>
      <h3>5.使用TiUI（开源，可选）</h3>
      <p>TiUI 是开源的，可参考选用。 使用 TiUI 方法如下:</p>
      <ul>
        <li>
          （1）引入头文件:
          <pre v-highlightA>
            <code>
              #import "TiUIManager.h"
            </code>
          </pre>
        </li>
        <li>
          （2）TiUIManager 创建 UI:
          <pre v-highlightA>
            <code>
              // 加载UI
              - (void)viewDidLoad {
                      [super viewDidLoad];
                          ...
                          ...
                      [[TiUIManager shareManager] loadToWindowDelegate:self];
                    //[[TiUIManager shareManager] loadToView:self.view forDelegate:self];
                    //UIView *view = [[TiUIManager shareManager] returnLoadToViewDelegate:self];
                  }
                  // 加载UI，添加TiUIManagerDelegate代理
                  // 测试拍照和切换摄像头可设置以下参数 注意showsDefaultUI默认为NO 在loadTo方法之前使用
                  //[TiUIManager shareManager].showsDefaultUI = YES;
                  //在loadTo方法之后添加默认按钮
                  //[self.view addSubview:[TiUIManager shareManager].defaultButton];
                  //弹出美颜UI 展示美颜界面调用以下方法
                  [[TiUIManager shareManager].tiUIViewBoxView.classifyView Judge_First:@""];
                  [[TiUIManager shareManager] showMainMenuView];
            </code>
          </pre>
        </li>
        <li>
          （3）TiUIManager 销毁 UI：
          <pre v-highlightA>
            <code>
              - (void)dealloc {
              [[TiSDKManager shareManager] destroy];
              }
            </code>
          </pre>
        </li>
      </ul>
      <h3>6. 集成开发</h3>
      <p>
        都成功导入工程后就可以进行集成开发了，如果过程中遇到问题，也可寻求技术支持。
      </p>
      <h3>6.1 初始化</h3>
      <p>
        TiSDK 初始化函数程序中调用一次即可生效，建议用户在 Application
        创建的时候调用;如果渲染功能使用不频繁，也可以在使用的时候调用，接口如下:
      </p>
      <pre v-highlightA>
        <code>
          - (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions
          {
          // 在线鉴权初始化方法
          //[TiSDK init:"在线鉴权的key"];
          // 在线鉴权初始化⽅法 - 有回调
          // 建议使⽤该方法进行在线鉴权初始化
            [TiSDK init:key CallBack:^(InitStatus initStatus) {
          // 回调相关
              }];
              //离线鉴权初始化⽅方法
              //TiSDK initOffline:"离线鉴权的 key"]; 
              return YES;
          }

        </code>
      </pre>
      <p>
        TiSDK 有初始化日志，在日志栏搜索
        <span>InitStatus</span>，可查看版本信息和初始化状态。
      </p>
      <h3>6.2 渲染步骤</h3>
      <ul>
        <li>
          （1）新建 <span>TiSDKManager</span>管理器对象，所有的渲染都必须通过
          <span>TiSDKManager</span> 对象调用，
          <span>TiSDKManager</span>
          的默认构造函数，新建默认渲染参数的渲染器对象;也可以通过具体功能函数来
          预设 <span>TiSDKManager</span>的渲染参数，代码如下:
          <pre v-highlightA>
            <code>
              //如果使⽤[[TiSDKManager alloc] init]方式初始化
              //生成默认参数渲染器
              self.tiSDKManager = [[TiSDKManager alloc] init]; //自定义渲染参数
              [self.tiSDKManager setBeautyEnable: true];
              [self.tiSDKManager setSkinWhitening: 100]; ......
              //如果使用[TiSDKManager shareManager]方式
              //自定义渲染参数
              [[TiSDKManager shareManager] setBeautyEnable:YES];
              [[TiSDKManager shareManager] setSkinWhitening: 100];
              ......
            </code>
          </pre>
        </li>
        <li>
          （2）渲染 GL_TEXTURE_2D 的纹理，调用 <span>TiSDKManager</span> 的
          <span>renderTexture2D</span>
          方法，返回 GL_TEXTURE_2D 类型的纹理 ID，代码如下:
          <pre v-highlightA>
            <code>
              // 默认人脸相关特效可支持人脸数
              int textureId = [[TiSDKManager shareManager] renderTexture2D: texture2D //2D纹理Id
                                                          Width: imageWidth //图像宽度
                                                          Height: imageHeight //图像高度
                                                        Rotation: rotation //TiRotation枚举，图像顺时针旋转的角度
                                                        Mirror: isMirror //图像是否左右镜像
              ];
              // 自定义人脸特效可支持人脸数[1,5]
              int textureId = [[TiSDKManager shareManager] renderTexture2D:texture2D];//2D纹理Id
                                                          Width: imageWidth //图像宽度
                                                          Height: imageHeight //图像高度
                                                        Rotation: rotation //TiRotation枚举，图像顺时针旋转的角度
                                                        Mirror: isMirror //图像是否左右镜像
                                                        FaceNumber: faceNumber //人脸相关特效可支持人脸数
              ];
            </code>
          </pre>
        </li>
        <li>
          （3）渲染 CVPixelBufferRef 视频帧，调用 <span>TiSDKManager</span> 的
          <span>renderPixels</span>
          方法，返回渲染后的视频 帧，代码如下:
          <pre v-highlightA>
            <code>
              // 默认人脸相关特效可支持人脸数 
              [[TiSDKManager shareManager] renderPixels: data //视频帧数组
                                      Format: format //视频帧格式，NV12 or BGRA 
                                        Width: imageWidth //图像宽度
                                      Height: imageHeight //图像高度
                                    Rotation: rotation //图像顺时针旋转的⻆角度 
                                    Mirror: isMirror //图像是否左右镜像
                            ];
              // 自定义人脸特效可支持人脸数[1,5]
              [[TiSDKManager shareManager] renderPixels: data //视频帧数组
                                      Format: format //视频帧格式，NV12 or BGRA
                                        Width: imageWidth //图像宽度
                                      Height: imageHeight //图像高度
                                    Rotation: rotation //图像顺时针旋转的角度
                                      Mirror: isMirror //图像是否左右镜像
                                  FaceNumber: faceNumber //人脸相关特效可支持人脸数
              ];
            </code>
          </pre>
        </li>
      </ul>
      <h3>6.3 销毁释放</h3>
      <p>
        渲染结束，要释放渲染资源，否则会造成内存泄漏，一般随着渲染数据回调接口的销毁释放，或
        者随着生命周期结束释放。释放资源调用 <span>TiSDKManager</span> 的
        <span>destroy</span> 方法，代码如下:
      </p>
      <pre v-highlightA>
        <code>
          //如果使用[[TiSDKManager alloc] init]方式初始化
          [self.tiSDKManager destroy];
          //如果使用[TiSDKManager shareManager]方式
          [[TiSDKManager shareManager] destroy];
         </code>
      </pre>
      <h3>6.4 资源配置到自己的服务器</h3>
      <p>TiSDK所有资源均支持放在用户自己的服务器，使用以下方法进行设置：</p>
      <pre v-highlightA>
        <code>
          + (void)setStickerUrl:(NSString *)url;//贴纸文件URL地址设置函数
          + (void)setStickerThumbUrl:(NSString *)url;//贴纸IconURL地址设置函数
          + (void)setGiftUrl:(NSString *)url;//礼物文件URL地址设置函数
          + (void)setGiftThumbUrl:(NSString *)url;//礼物IconURL地址设置函数
          + (void)setWatermarkUrl:(NSString *)url;//水印文件URL地址设置函数
          + (void)setWatermarkThumbUrl:(NSString *)url;//水印IconURL地址设置函数
          + (void)setMaskUrl:(NSString *)url;//面具文件URL地址设置函数
          + (void)setMaskThumbUrl:(NSString *)url;//面具IconURL地址设置函数
          + (void)setGreenScreenUrl:(NSString *)url;//绿幕文件URL地址设置函数
          + (void)setGreenScreenThumbUrl:(NSString *)url;//绿幕IconURL地址设置函数
          + (void)setInteractionUrl:(NSString *)url;//互动文件URL地址设置函数
          + (void)setInteractionThumbUrl:(NSString *)url;//互动IconURL地址设置函数
          + (void)setMakeupUrl:(NSString *)url;//美妆文件URL地址设置函数
          + (void)setMakeupThumbUrl:(NSString *)url;//美妆IconURL地址设置函数
          + (void)setPortraitUrl:(NSString *)url;//人像抠图文件URL地址设置函数
          + (void)setPortraitThumbUrl:(NSString *)url;//人像抠图IconURL地址设置函数
         </code>
      </pre>
      <p>具体使用方法如下：</p>
      <pre v-highlightA>
        <code>
          [TiSDK setStickerUrl:(NSString *)url];
         </code>
      </pre>
      <h3>6.5 日志功能</h3>
      <p>可采用以下方法关闭或打开SDK调试日志:</p>
      <pre v-highlightA>
        <code>
          /**
          * 打开/关闭日志
          */
          + (void)setLog:(BOOL)enable;

          //调用方式
          [TiSDK setLog:(BOOL)enable];
         </code>
      </pre>
    </div>
    <!-- 英文 -->
    <div v-else>
      <h2>iOS Platform</h2>
      <h3>1.Application Test Key</h3>
      <p>
        TiSDK’s initialization interface, which uses Key for authentication,
        requires a business application, application method:
      </p>
      <ul>
        <li>（1）Call 17136231898 for consultation.</li>
        <li>（2）Add QQ:3599350770 / WeChat:17136231898 for consultation.</li>
      </ul>
      <h3>2. Download Configuration SDK</h3>
      <ul>
        <li>
          （1）Software requirements: iOS 8.0 and above, Xcode 8 and above
        </li>
        <li>
          （2）The latest version download integration:
          <ul class="ul_style">
            <li>
              SDK download address:
              <a href="https://gitee.com/tillusory/TiSDK_iOS">TiSDK</a>
            </li>
            <li>
              Demo download address:
              <a href="https://gitee.com/tillusory/Ti_Demo_iOS">Ti_Demo_iOS</a>
            </li>
          </ul>
        </li>
      </ul>
      <h3>3. Check Resources</h3>
      <p>
        User Confirms IOS Version TiSDK Package<span>TiSDK_iOS</span>
        Contains:<span>TiSDK.framework</span>、<span
          >TiUI Folder(optional),</span
        >
        <span>TiSDKResource.bundle</span>TiSDKResource.bundle`. SDK Resource
        Diagram:
      </p>
      <img src="@/assets/developer/ios1.png" alt="SDK资源示意图" />
      <h3>4. Import Project</h3>
      <ul>
        <li>
          (1) <span>TiSDK_iOS</span>Folder is copied to the
          <span>IOS</span> project directory and then introduced into the
          project where <span>TiSDK.framework</span> Both<span>Masonry</span>and
          <span>SSZipArchive</span>in the UI file are dynamic libraries that
          need to be embedded;
        </li>
        <li>
          (2) If you have added <span>Masonry</span> and
          <span>SSZipArchive</span>to your original project, you can delete
          <span>Masonry.framework</span>、
          <span>ZipArchive. framework</span>from the
          <span>TiUITool</span> directory to avoid conflicts;
        </li>
        <li>(3) Add Dependent Library <span>libc++.tbd</span>;</li>
        <li>
          (4) Dependent library and dynamic library embedding operation diagram:
          <img src="@/assets/developer/ios2.png" alt="嵌入操作示意图" />
        </li>
      </ul>
      <h3>5.Use TiUI (open source, optional)</h3>
      <p>
        TiUI is open source and can be referenced and used. Use the TiUI method
        as follows:
      </p>
      <ul>
        <li>
          （1）Introduce a header file:
          <pre v-highlightA>
            <code>
              #import "TiUIManager.h"
            </code>
          </pre>
        </li>
        <li>
          （2）TiUIManager creates UI:
          <pre v-highlightA>
            <code>
              //  Load UI
              - (void)viewDidLoad {
                      [super viewDidLoad];
                          ...
                          ...
                      [[TiUIManager shareManager] loadToWindowDelegate:self];
                    //[[TiUIManager shareManager] loadToView:self.view forDelegate:self];
                    //UIView *view = [[TiUIManager shareManager] returnLoadToViewDelegate:self];
                  }
                  // Test the camera and switch cameras to set the following parameters.
                  // Note that showsDefaultUI defaults to NO,Use before the loadTo 
                  //[TiUIManager shareManager].showsDefaultUI = YES;
                  //Add default button after the loadTo method
                  //[self.view addSubview:[TiUIManager shareManager].defaultButton];
                  //Pop up and display the SDKUI interface to call this method.
                  [[TiUIManager shareManager].tiUIViewBoxView.classifyView Judge_First:@""];
                  [[TiUIManager shareManager] showMainMenuView];
            </code>
          </pre>
        </li>
        <li>
          （3）TiUIManager destroy UI：
          <pre v-highlightA>
            <code>
             - (void)dealloc {
             [[TiSDKManager shareManager] destroy];
            }
            </code>
          </pre>
        </li>
      </ul>
      <h3>6. Integrated development</h3>
      <p>
        Once the project has been successfully imported, it can be integrated
        and developed, and if problems arise during the process, technical
        support can be sought.
      </p>
      <h3>6.1 Initialization</h3>
      <p>
        TiSDK initialization function program can be called once to take effect,
        it is recommended that the user call when the application is created; if
        the rendering function is used infrequently, it can also be called when
        used, the interface is as follows:
      </p>
      <pre v-highlightA>
        <code>
           - (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions
          {
          // Online Authentication Method
          //[TiSDK init:"Key for online authentication"];
          // Online Authentication Method -  with callback
          // It is suggested that this method be used for online authentication
            [TiSDK init:key CallBack:^(InitStatus initStatus) {

              }];
              //Offline authentication method
              //TiSDK initOffline:"Key for offline authentication"]; 
              return YES;
          }
        </code>
      </pre>
      <p>
        TiSDK has an initialization log. Search the log bar for
        <span>InitStatus</span>to see version information and initialization
        status.
      </p>
      <h3>6.2 Rendering Steps</h3>
      <ul>
        <li>
          （1）New <span>TiSDKManager</span> manager object, all rendering must
          be invoked through <span>TiSDKManager</span> object.You can also
          preset the rendering parameters of `TiSDKManager’through specific
          function codes as follows:
          <pre v-highlightA>
            <code>
              //If [[TiSDKManager alloc] init] mode is initialized.
              //Generate default parameter renderer
              self.tiSDKManager = [[TiSDKManager alloc] init];
              [self.tiSDKManager setBeautyEnable: true];
              [self.tiSDKManager setSkinWhitening: 100]; ......
              //If [TiSDKManager ShareManager] is used
              //Custom rendering parameters
              [[TiSDKManager shareManager] setBeautyEnable:YES];
              [[TiSDKManager shareManager] setSkinWhitening: 100];
              ......
            </code>
          </pre>
        </li>
        <li>
          Render the texture of GL_TEXTURE_2D, call the<span
            >renderTexture2D</span
          >
          method of <span>TiSDKManager</span> , and return the texture ID of
          type GL_TEXTURE_2D with the following code:
          <pre v-highlightA>
            <code>
              // Default number of faces supported.
              int textureId = [[TiSDKManager shareManager] renderTexture2D: texture2D
                                                          Width: imageWidth
                                                          Height: imageHeight
                                                        Rotation: rotation
                                                        Mirror: isMirror
              ];
              // Customize the number of faces that can be supported.[1,5]
              int textureId = [[TiSDKManager shareManager] renderTexture2D:texture2D];
                                                          Width: imageWidth
                                                          Height: imageHeight
                                                        Rotation: rotation
                                                        Mirror: isMirror
                                                        FaceNumber: faceNumber
              ];
            </code>
          </pre>
        </li>
        <li>
          （3）Render the CVPixelBufferRef video frame, call the
          <span>renderPixels</span> method of<span>TiSDKManager</span> , and
          return the rendered video frame with the following code:
          <pre v-highlightA>
            <code>
              // Default number of faces supported.
              [[TiSDKManager shareManager] renderPixels: data
                                      Format: format //NV12 or BGRA 
                                        Width: imageWidth
                                      Height: imageHeight
                                    Rotation: rotation
                                    Mirror: isMirror
                            ];
              // Customize the number of faces that can be supported.[1,5]
              [[TiSDKManager shareManager] renderPixels: data
                                      Format: format //NV12 or BGRA
                                        Width: imageWidth
                                      Height: imageHeight
                                    Rotation: rotation
                                      Mirror: isMirror
                                  FaceNumber: faceNumber
            ];
            </code>
          </pre>
        </li>
      </ul>
      <h3>6.3 Destroy Release</h3>
      <p>
        At the end of rendering, the rendering resources should be released,
        otherwise memory leaks will occur, typically with the destruction of the
        rendering data callback interface or with the end of the lifecycle.
        Releasing resources calls method <span>destroy</span>
        <span>destroy</span> <span>TiSDKManager</span> ,The code is as follows:
      </p>
      <pre v-highlightA>
        <code>
          //If using [[TiSDKManager alloc] init].
          [self.tiSDKManager destroy];
          //If using [TiSDKManager shareManager].
          [[TiSDKManager shareManager] destroy];
         </code>
      </pre>
      <h3>6.4 Resources Configured to Your Server</h3>
      <p>
        All TiSDK resources are supported on the user’s own server and are set
        up using the following methods:
      </p>
      <pre v-highlightA>
        <code>
          + (void)setStickerUrl:(NSString *)url;
          + (void)setStickerThumbUrl:(NSString *)url;
          + (void)setGiftUrl:(NSString *)url;
          + (void)setGiftThumbUrl:(NSString *)url;
          + (void)setWatermarkUrl:(NSString *)url;
          + (void)setWatermarkThumbUrl:(NSString *)url;
          + (void)setMaskUrl:(NSString *)url;
          + (void)setMaskThumbUrl:(NSString *)url;
          + (void)setGreenScreenUrl:(NSString *)url;
          + (void)setGreenScreenThumbUrl:(NSString *)url;
          + (void)setInteractionUrl:(NSString *)url;
          + (void)setInteractionThumbUrl:(NSString *)url;
          + (void)setMakeupUrl:(NSString *)url;
          + (void)setMakeupThumbUrl:(NSString *)url;
          + (void)setPortraitUrl:(NSString *)url;
          + (void)setPortraitThumbUrl:(NSString *)url;
         </code>
      </pre>
      <p>The specific methods of use are as follows:</p>
      <pre v-highlightA>
        <code>
          [TiSDK setStickerUrl:(NSString *)url];
         </code>
      </pre>
      <h3>6.5 Logging Function</h3>
      <p>You can close or open the SDK debug log in the following ways:</p>
      <pre v-highlightA>
        <code>
          /**
          * Open/Close Log
          */
          + (void)setLog:(BOOL)enable;

          //Call Method
          [TiSDK setLog:(BOOL)enable];
         </code>
      </pre>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #3c4858;
}
h3 {
  font-size: 18px;
  font-weight: 400;
  color: #3c4858;
  margin: 20px 0;
}
p {
  width: 763px;
  margin: 20px 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
li {
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
span {
  color: #e91e63;
}
img {
  width: 431px;
  margin: 20px 60px;
}
.ul_style {
  padding-left: 10px;
  li {
    line-height: 32px;
    list-style: disc inside none;
  }
  a {
    color: #4c8cf5;
    text-decoration: underline;
  }
}
</style>